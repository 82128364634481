import { dateNow } from '@/helpers/DateTimeHelper';
import { ConsentTypesPayloadInterface } from '@/interfaces/consents/ConsentTypesPayloadInterface';
import * as api from '@/api/consent';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import { Response } from '@/api/types';

export async function postConsentTypes({
  consentTypes,
  merchant_application_uuid,
  consumer_application_uuid,
  appId,
  consentedOn,
  entity,
}: ConsentTypesPayloadInterface): Promise<number> {
  const failStatus = 0;
  if (!consentTypes) return failStatus;

  appId = appId || merchant_application_uuid || consumer_application_uuid;
  if (!appId) return failStatus;

  const promises: Array<Response<any>> = consentTypes
    .map((consent_type: number) => {
      const payload: any = { consent_type };
      if (consentedOn) {
        payload.consented_on = dateNow();
      }

      return api.postConsentTypes(entity, appId!, payload);
    });

  try {
    await Promise.all(promises);
    return 201;
  } catch (e: any) {
    return e.response?.status?.toString() || failStatus;
  }
}

export async function patchConsentTypes({
  consentTypes,
  merchant_application_uuid,
  consumer_application_uuid,
  appId,
  entity,
}: ConsentTypesPayloadInterface): Promise<number> {
  const failStatus = 0;
  if (!consentTypes) return failStatus;

  const payload = { consented_on: dateNow() };
  appId = appId || merchant_application_uuid || consumer_application_uuid;
  if (!appId) return failStatus;

  const promises: Array<Response<any>> = consentTypes
    .map((consent_type: number) => {
      return api.patchConsentType(entity, appId!, consent_type, payload);
    });

  try {
    await Promise.all(promises);
    return 201;
  } catch (e) {
    return failStatus;
  }
}

export async function getConsentTypes({
  consentTypes,
  merchant_application_uuid,
  consumer_application_uuid,
  appId,
  entity,
  userId,
}: ConsentTypesPayloadInterface): Promise<number[]> {
  const failStatus = [0];
  if (!consentTypes) return failStatus;

  appId = appId || merchant_application_uuid || consumer_application_uuid;
  if (!appId) return failStatus;

  const response = await api.getConsentTypes(entity, appId);
  const consentTypesList: any = response.data;
  const consentIds: number[] = [];

  consentTypesList.forEach((consentType: any) => {
    if (!consentTypes.includes(consentType.consent_type)) return;

    if (userId) {
      if (consentType.user === userId) consentIds.push(consentType.id);
    } else {
      consentIds.push(consentType.id);
    }
  });
  return consentIds;
}

export async function patchAvailableConsents(
  types: Array<number>,
  appId: string,
  entity: ConsentEntityTypes,
  userId?: number,
) {
  const availableConsents = await getConsentTypes({
    consentTypes: types, appId, entity, userId,
  });
  return patchConsentTypes({ consentTypes: availableConsents, appId, entity });
}
